<template>
  <div>
    <b-popover
      customClass="hm-popover"
      :target="target"
      :placement="placement"
      v-bind="$attrs"
      :click="clickTrigger"
      ref="popover"
    >
      <!-- slot with prop function hide -->
      <div @click="onContainerClick">
        <slot :hide="hide"></slot>
      </div>
    </b-popover>
  </div>
</template>

<script lang="ts">
export default {
  name: 'HMPopover',
  props: {
    target: {
      type: String,
      required: true,
    },
    placement: {
      type: String,
      default: 'right-bottom',
    },
    clickTrigger: {
      type: Boolean,
      default: true
    },
    hideOnClick: {
      type: Boolean,
      default: true
    },
  },
  methods: {
    hide(e) {
      this.$refs?.popover?.hide(e);
    },
    onContainerClick(e) {
      if (this.hideOnClick) {
        this.hide(e);
      }
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/components-variables.scss';

.b-popover {
  border-color: $light-gray !important;

  &.bs-popover-bottom,
  &.bs-popover-auto[x-placement^='bottom'] {
    .arrow {
      &:before {
        border-bottom-color: $light-gray !important;
      }
    }
  }

  &.bs-popover-right,
  &.bs-popover-auto[x-placement^='right'] {
    .arrow {
      &:before {
        border-right-color: $light-gray !important;
      }
    }
  }

  &.bs-popover-left,
  &.bs-popover-auto[x-placement^='left'] {
    .arrow {
      &:before {
        border-left-color: $light-gray !important;
      }
    }
  }

  &.bs-popover-top,
  &.bs-popover-auto[x-placement^='top'] {
    .arrow {
      &:before {
        border-top-color: $light-gray !important;
      }
    }
  }

  .popover-body {
    padding: 0 !important;
    // margin: 0 !important;
  }
}
</style>
